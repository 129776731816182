@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar {
    scrollbar-color: #ff357e white;
    scrollbar-width: thin;
  }

  .scrollbar::-webkit-scrollbar {
    width: 2px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background-color: white;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #ff357e;
    border-radius: 5px;
  }
}